document.addEventListener("DOMContentLoaded", () => {
	const filter = document.querySelector(".js-ArticleListFilter");

	if (filter) {
		const toggle = filter.querySelector(".ArticleListFilter-toggle");
		const additionalItems = Array.from(
			filter.querySelectorAll(".ArticleListFilter-item[hidden]")
		);

		if (toggle && additionalItems) {
			toggle.addEventListener("click", (e) => {
				e.preventDefault();

				toggleVisibilityOfAdditionalItems(toggle, additionalItems);
			});
		}
	}

	/**
	 *
	 * @param {HTMLButtonElement} toggle
	 * @param {Array<HTMLElement>} additionalItems
	 */
	function toggleVisibilityOfAdditionalItems(toggle, additionalItems) {
		const show = toggle.getAttribute("aria-expanded") === "false";

		additionalItems.forEach((additionalItem) => {
			additionalItem.hidden = !show;
		});

		toggle.setAttribute("aria-expanded", show ? "true" : "false");
		toggle.textContent = toggle.dataset[show ? "copyHide" : "copyShow"];
	}
});
