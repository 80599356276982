class SearchOverlay extends HTMLElement {
	#elements;

	constructor() {
		super();

		const setInertSelector = this.getAttribute("set-inert") ?? "body";
		const triggerURL = this.getAttribute("trigger-url") ?? "/articles/";
		const triggerLinkSelector = `a[href*="${triggerURL}"]`;
		const triggerFormSelector = `form[action*="${triggerURL}"]`;

		this.#elements = {
			inert: /** @type {HTMLElement|null} */ (
				document.querySelector(setInertSelector)
			),
			triggerLinks: /** @type {NodeListOf<HTMLAnchorElement>} */ (
				document.querySelectorAll(triggerLinkSelector)
			),
			triggerForms: /** @type {NodeListOf<HTMLFormElement>} */ (
				document.querySelectorAll(triggerFormSelector)
			),
		};

		for (const link of this.#elements.triggerLinks) {
			link.addEventListener("click", this.#show.bind(this));
		}

		for (const form of this.#elements.triggerForms) {
			form.addEventListener("submit", this.#show.bind(this));
		}

		/**
		 * Hide spinner on back/forward-cache navigation.
		 * This may introduce a short “flicker” where the spinner is shown,
		 * which seems to be unavoidable due to the async nature of events.
		 */
		window.addEventListener("pageshow", this.#hide.bind(this));
	}

	#show() {
		this.toggleAttribute("hidden", false);
		this.#elements.inert?.toggleAttribute("inert", true);
		document.body.style.overflow = "hidden";
	}

	#hide() {
		this.toggleAttribute("hidden", true);
		this.#elements.inert?.toggleAttribute("inert", false);
		document.body.style.overflow = "";
	}
}

customElements.define("fta-search-overlay", SearchOverlay);
