const SELECTORS = {
	PRODUCT_IMAGE: "ArticleView-imageContainer",
	THUMBNAIL: "ArticleView-imageGallery--thumbnail",
};

// Get all thumbnails and product image elements
const productImages = document.querySelectorAll(`.${SELECTORS.PRODUCT_IMAGE}`);
const thumbnails = document.querySelectorAll(`.${SELECTORS.THUMBNAIL}`);

const onSelectImage = (imageIndex) => {
	if (thumbnails.length <= 1) return;

	// Unselect previous image
	productImages.forEach((productImage) => {
		productImage.setAttribute("hidden", true);
	});
	thumbnails.forEach((thumbnail) => thumbnail.removeAttribute("aria-current"));

	const selectedProductImage = productImages[imageIndex];
	const selectedThumbnail = thumbnails[imageIndex];

	// Select new image
	selectedProductImage.removeAttribute("hidden");
	selectedThumbnail.setAttribute("aria-current", true);
};

// Add event listeners to thumbnails
thumbnails.forEach((thumbnail) => {
	thumbnail.addEventListener("click", (event) => {
		const { id } = event.target;
		const imageIndex = parseInt(id.match(/\d+/)[0], 10);

		onSelectImage(imageIndex);
	});
});

// Initialize component
onSelectImage(0);
