class MultiSelect {
	/**
	 * @param {HTMLElement} element
	 */
	constructor(element) {
		this.element = element;
		this.label = this.element.querySelector(".MultiSelect-labelInner");
		this.inputs = Array.from(this.element.querySelectorAll("input"));

		this.clickHandler = this.closeOnOutsideClick.bind(this);

		this.element.addEventListener("toggle", (e) => {
			const { open } = e.target;

			if (open) {
				document.body.addEventListener("click", this.clickHandler);
			} else {
				document.body.removeEventListener("click", this.clickHandler);
			}
		});

		this.renderLabel();

		this.inputs.forEach((input) => {
			input.addEventListener("change", () => {
				this.renderLabel();
			});
		});
	}

	/**
	 * @returns {void}
	 */
	renderLabel() {
		const checkedInputs = this.inputs.filter((el) => el.checked);

		if (checkedInputs.length > 0) {
			this.label.textContent = checkedInputs
				.map((el) => {
					const label = document.querySelector(`[for="${el.id}"]`);
					return label ? label.textContent : null;
				})
				.join(", ");
		} else {
			this.label.textContent = this.label.dataset.emptyValue || "-";
		}
	}

	/**
	 * @param {Event} e
	 */
	closeOnOutsideClick(e) {
		if (!this.element.contains(e.target)) {
			this.element.open = false;
		}
	}
}

document.addEventListener("DOMContentLoaded", () => {
	Array.from(document.querySelectorAll(".js-MultiSelect")).forEach(
		(multiSelect) => new MultiSelect(multiSelect)
	);
});
