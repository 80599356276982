const SELECTORS = {
	ARTICLES_LIST: ".js-ArticleList",
	SELECT: ".js-ArticleList-articlesPerPage",
};

export default class ArticleList {
	/**
	 * @param {HTMLElement} element
	 */
	constructor(element) {
		this.element = element;
		this.elements = {
			select: this.element
				.querySelector(SELECTORS.SELECT)
				?.querySelector("select"),
		};

		if (!this.elements.select) return;

		this.addListeners();
	}

	/**
	 * Adds all event listeners
	 */
	addListeners() {
		this.redirectOnSelectChange();
	}

	/**
	 * Redirects the user to the same page with chosen number of articles
	 */
	redirectOnSelectChange() {
		this.elements.select.addEventListener("change", (event) => {
			const selectedOption = event.target.selectedOptions[0];

			const { url } = selectedOption.dataset;
			window.location.href = url;
		});
	}
}

document
	.querySelectorAll(SELECTORS.ARTICLES_LIST)
	.forEach((element) => new ArticleList(element));
