const SELECTORS = {
	container: ".js-Slider",
	buttonPrevious: ".js-Slider-navigationButton--previous",
	buttonNext: ".js-Slider-navigationButton--next",
	track: ".js-Slider-track",
	slide: ".js-Slider-slide",
};

class Slider {
	#elements;

	/**
	 * Depending on resolution and scaling factor,
	 * the track size and position might not perfectly align.
	 * A small rounding offset ensures buttons are always correctly disabled.
	 */
	#roundingOffset = 2;

	/**
	 * @param {HTMLDivElement} element
	 */
	constructor(element) {
		this.#elements = {
			buttonPrevious: /** @type {HTMLButtonElement} */ (
				element.querySelector(SELECTORS.buttonPrevious)
			),
			buttonNext: /** @type {HTMLButtonElement} */ (
				element.querySelector(SELECTORS.buttonNext)
			),
			track: /** @type {HTMLDivElement} */ (
				element.querySelector(SELECTORS.track)
			),
			slides: /** @type {NodeListOf<HTMLDivElement>} */ (
				element.querySelectorAll(SELECTORS.slide)
			),
		};

		this.#addEventListeners();
	}

	#addEventListeners() {
		this.#elements.buttonPrevious.addEventListener(
			"click",
			this.#showPreviousSlide.bind(this)
		);
		this.#elements.buttonNext.addEventListener(
			"click",
			this.#showNextSlide.bind(this)
		);

		this.#elements.track.addEventListener("scroll", this.#onScroll.bind(this));

		/**
		 * Resize observers are triggerd once on observe start, so the initial
		 * button state and visibility doesn't need to be set in the constructor.
		 */
		const resizeObserver = new ResizeObserver(() => this.#onResize());
		resizeObserver.observe(this.#elements.track);
	}

	#onScroll() {
		requestAnimationFrame(() => this.#setButtonState());
	}

	#onResize() {
		requestAnimationFrame(() => {
			this.#setButtonState();
			this.#setButtonVisibility();
		});
	}

	#showPreviousSlide() {
		this.#elements.track.scrollLeft -= this.#slideSize;
	}

	#showNextSlide() {
		this.#elements.track.scrollLeft += this.#slideSize;
	}

	#setButtonState() {
		if (this.#trackVisibleSize === this.#trackTotalSize) {
			this.#elements.buttonPrevious.toggleAttribute("disabled", true);
			this.#elements.buttonNext.toggleAttribute("disabled", true);
			return;
		}

		if (this.#trackPosition - this.#roundingOffset <= 0) {
			this.#elements.buttonPrevious.toggleAttribute("disabled", true);
			this.#elements.buttonNext.toggleAttribute("disabled", false);
			return;
		}

		if (
			this.#trackPosition + this.#trackVisibleSize + this.#roundingOffset >=
			this.#trackTotalSize
		) {
			this.#elements.buttonPrevious.toggleAttribute("disabled", false);
			this.#elements.buttonNext.toggleAttribute("disabled", true);
			return;
		}

		this.#elements.buttonPrevious.toggleAttribute("disabled", false);
		this.#elements.buttonNext.toggleAttribute("disabled", false);
	}

	#setButtonVisibility() {
		const isVisible =
			this.#trackVisibleSize + this.#roundingOffset < this.#trackTotalSize;

		this.#elements.buttonPrevious.toggleAttribute("hidden", !isVisible);
		this.#elements.buttonNext.toggleAttribute("hidden", !isVisible);
	}

	get #trackTotalSize() {
		return this.#elements.track.scrollWidth;
	}

	get #trackVisibleSize() {
		return this.#elements.track.offsetWidth;
	}

	get #trackPosition() {
		return this.#elements.track.scrollLeft;
	}

	get #slideSize() {
		return this.#elements.slides[0].offsetWidth;
	}
}

document.addEventListener("DOMContentLoaded", () => {
	document
		.querySelectorAll(SELECTORS.container)
		.forEach((element) => new Slider(element));
});
